import React from "react";
import styles from "./styles.module.css";
import GetWeb_lg from "../../../../../assets/img/GetWeb_lg.gif";
import GetWeb_sm from "../../../../../assets/img/GetWeb_sm.gif";
import getweb_img from "../../../../../assets/img/getweb_img.jpg";

export default function SolutionsGetWeb() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Solutions GetWeb</h1>
        <div className={styles.column}>
          <img src={GetWeb_lg} alt="Solutions GetWeb"></img>
          <h2>Simplifiez-vous la vie!</h2>
          <p>
            Choisissez parmi nos solutions complÃ¨tes, faciles et accessibles.
          </p>
          <h3>On s'occupe de tout pour vous!</h3>
          <h3>Faites le 1 866 954 1230</h3>
          <p>
            Laissez-nous mettre notre expÃ©rience au service de votre
            entreprise!
          </p>
          <img src={getweb_img} alt="On s'occupe de tout pour vous Image"></img>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Ensemble logo</h2>
            <img src={GetWeb_sm} alt="Solutions GetWeb"></img>
            <h3>Combinez et économisez!</h3>
            <p>
              De nouveaux clients et une nouvelle image pour UN SEUL prix
              MODIQUE. Plus de 80 % des cyberconsommateurs font maintenant leurs
              recherches ou leurs achats en ligneâ. Captez leur attention!
            </p>
            <ul>
              <li>Un appel suffit pour faire rapidement bonne impression!</li>
              <li>
                Excellent site + marque captivante pour un seul prix
                exceptionnellement bas.
              </li>
            </ul>
            <p>
              Avec l'ensemble Web/Logo Deluxe, vous vous assurez d'une prÃ©sence
              professionnelle en ligne pour seulement 94,99 $ par mois durant 12
              mois.*
            </p>
            <p>
              Un logo sur mesure, tout nouveau ou rafraÃ®chi. 6 concepts, 6
              modifications, le tout en une semaine ou moins! Un site
              professionnel GetWeb de 5 pages
            </p>
            <p>Appelez sans tarder! 1 866 954 1230</p>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Forfait de lancement</h2>
            <img src={GetWeb_sm} alt="Forfait de lancement Image"></img>
            <h3>39,99 $ par mois</h3>
            <ul>
              <li>
                Enregistrement annuel GRATUIT du nom de domaine ex.:
                nomdecompagnie.ca
              </li>
              <li>
                Site HTML de 3 pages conçu par des professionnels utilisant des
                modèles avancés.
              </li>
              <li>Lancer l'hébergement Internet</li>
              <li>
                Soumission aux moteurs de recherche une fois par an. Rendez
                votre site facile à trouver grâce aux moteurs de recherche.
              </li>
              <li>Maintenance (une heure), deux fois par an.</li>
              <li>Soutien technique pour site Internet, 24 heures sur 24.</li>
            </ul>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Forfait professionnel</h2>
            <img src={GetWeb_sm} alt="Forfait professionnel Image"></img>
            <h3>69,99 $ par mois** durant 12 mois</h3>
            <ul>
              <li>
                Enregistrement annuel GRATUIT du nom de domaine ex.:
                nomdecompagnie.ca
              </li>
              <li>
                Site HTML de 5 pages conçu par des professionnels utilisant des
                modèles avancés.
              </li>
              <li>Hébergement Internet professionnel standard</li>
              <li>
                S Soumission aux moteurs de recherche, deux fois par an. Rendez
                votre site facile à trouver grâce aux moteurs de recherche.
              </li>
              <li>Une heure d'entretien par mois.</li>
              <li>Soutien technique pour site Internet, 24 heures sur 24.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <h2>Faites grimper vos ventes</h2>
          <ul>
            <li>Exposez votre entreprise aux marchés existants et nouveaux.</li>
            <li>Attirez de nouveaux clients.</li>
            <li>Transformez les visiteurs de votre site en clients.</li>
            <li>Vendez plus à vos clients existants.</li>
          </ul>
        </div>
        <div className={styles.column}>
          <h2>Conservez vos clientsâ</h2>
          <ul>
            <li>
              Fournissez des réponses aux questions courantes de vos clients.
            </li>
            <li>
              Enrichissez votre relation avec vos clients grâce à la
              communication.
            </li>
            <li>Fournissez un meilleur service que vos concurrents.</li>
          </ul>
        </div>
      </div>
      <small>
        * 94,99 $ par mois pendant un an. Ce prix tombe à 39,99 $ par mois la
        deuxième année. Le logo sera affiché sur le site Web et sera livré sur
        CD-ROM dans différents formats. Soumis aux termes et conditions. ** Des
        frais d'entretien et d'hébergement continus de 39,99 $ seront perçus
        chaque mois à compter du début de votre deuxième année.
      </small>
    </div>
  );
}
