import React from "react";
import Layout from "../../../../other/layout";
import SolutionsGetWeb from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/SolutionsGetWeb/SolutionsGetWeb";
import SEO from "../../../../other/seo";

export default function solutionsGetWeb() {
  return (
    <Layout>
      <SEO title="Solutions GetWeb" />

      <SolutionsGetWeb />
    </Layout>
  );
}
